import { useEffect, useState } from 'react';
import { Button, Fade } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Colors } from '.';

export default ({ label, textToCopy, htmlTextToCopy }: { label: string; textToCopy: string | undefined; htmlTextToCopy?: string | undefined }) => {
    const [copiedText, setCopiedText] = useState<boolean>(false);

    const copyToClipboard = (textToCopy: string) => {
        const listener = (e: ClipboardEvent) => {
            !!htmlTextToCopy && e.clipboardData?.setData('text/html', htmlTextToCopy);
            e.clipboardData?.setData('text/plain', textToCopy);
            e.preventDefault();
            setCopiedText(true);
        }

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }

    useEffect(() => {
        let timer = copiedText ? setTimeout(() => setCopiedText(false), 3000) : undefined;
        return () => { timer && clearTimeout(timer) };
    }, [copiedText]);

    return <>
        {!copiedText ?
            <Fade in={!copiedText}>
                <Button fullWidth variant="contained" color="primary" endIcon={<FileCopyIcon />} onClick={()=> copyToClipboard(textToCopy || '')}>{label}</Button>
            </Fade>
            : <Fade in={copiedText}>
                <Alert variant="outlined" severity="success" style={{ paddingTop: 1, paddingBottom: 1, borderRadius: '1.5rem', color: Colors.Blue, justifyContent: 'center' }}>Successfully copied</Alert>
            </Fade>
        }
    </>
}
