import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { AddressLine, MetadataResult, ProblemDetails } from '../api';
import { getApis } from './apiSelector';

const initialState: {
    loading: boolean[];
    isLoading: boolean;
    requestId?: string;
    addresses?: AddressLine[];
    addressInfo?: MetadataResult;
    errors?: ProblemDetails;
} = {
    loading: [],
    isLoading: false
};

export const searchAsync = createAsyncThunk<
    AddressLine[],
    string,
    { state: RootState }
>('addressFinder/search', (payload: string, { getState, rejectWithValue }) =>
    getApis(getState())
        .addressFinderClient.search(payload, 10)
        .catch(rejectWithValue)
);

export const getMetadataAsync = createAsyncThunk<
    MetadataResult,
    string,
    { state: RootState }
>(
    'addressFinder/getMetadata',
    (payload: string, { getState, rejectWithValue }) =>
        getApis(getState()).addressFinderClient.get(payload).catch(rejectWithValue)
);

export const addressFinderSlice = createSlice({
    name: 'addressFinder',
    initialState,
    reducers: {
        clear(state) {
            state.isLoading = false;
            state.errors = undefined;
            state.addresses = undefined;
            state.addressInfo = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchAsync.pending, (state, action) => {
                state.loading.push(true);
                state.isLoading = true;
                state.requestId = action.meta.requestId;
                state.errors = undefined;
                state.addresses = undefined;
                state.addressInfo = undefined;
            })
            .addCase(searchAsync.fulfilled, (state, action) => {
                if (state.requestId == action.meta.requestId) {
                    state.loading.pop();
                    state.isLoading = !!state.loading.length;
                    state.addresses = action.payload;
                }
            })
            .addCase(searchAsync.rejected, (state, action) => {
                if (state.requestId == action.meta.requestId) {
                    state.loading.pop();
                    state.isLoading = !!state.loading.length;
                    state.errors = action.payload as ProblemDetails;
                }
            })
            .addCase(getMetadataAsync.pending, (state, action) => {
                state.loading.push(true);
                state.isLoading = true;
                state.requestId = action.meta.requestId;
                state.errors = undefined;
                state.addresses = undefined;
                state.addressInfo = undefined;
            })
            .addCase(getMetadataAsync.fulfilled, (state, action) => {
                if (state.requestId == action.meta.requestId) {
                    state.loading.pop();
                    state.isLoading = !!state.loading.length;
                    state.addressInfo = action.payload;
                }
            })
            .addCase(getMetadataAsync.rejected, (state, action) => {
                if (state.requestId == action.meta.requestId) {
                    state.loading.pop();
                    state.isLoading = !!state.loading.length;
                    state.errors = action.payload as ProblemDetails;
                }
            });
    },
});

export const { clear } = addressFinderSlice.actions;

export default addressFinderSlice.reducer;
