export const White = '#FCFCFC'; // NZ Funds White
export const Black = '#232323'; // NZ Funds Black
export const LightGrey = '#F4F4F4'; // Background
export const MediumGrey = '#E5E5E5';
export const Grey = '#BDBDBD';
export const DarkGrey = '#656565';
export const Blue = '#0053F3'; // NZ Funds Brand Blue
export const Red = '#F44336';
export const Orange = '#F27F14';
export const Green = '#21C559'; // KiwiSaver Green
