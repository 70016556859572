import { ThemeProvider } from "@material-ui/styles";
import { AppConfigProvider } from "@nzfm/app-config";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { FLAG_SKIP_NCC_SCREEN } from "./common/types";
import store from "./store";
import theme from "./theme";

const MONITORED_FLAGS = [FLAG_SKIP_NCC_SCREEN];

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppConfigProvider appConfigUri={process.env.REACT_APP_APPCS_URL!} monitoredFlags={MONITORED_FLAGS}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppConfigProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
