import { isValidIRDNumber } from "@fnzc/nz-ird-validator";
import { differenceInYears } from "date-fns";
import * as yup from "yup";
import { ApplicationClientRoles, ClientDto } from "../api";
import {
  AddressDto,
  AdviceDelivery,
  AdviceStatuses,
  AdviceType,
  AmlStatuses,
  ApplicationClientDto,
  ClientStatuses,
  ConfirmationType,
  CountryResidedDto,
  DistributionOptions,
  DriverLicenceDto,
  InvestmentOptions,
  KiwiSaverApplicationDto,
  OnboardDelivery,
  OtherTaxCountryDto,
  PassportDto,
  Product,
  RegoDto,
  UpsertDefautlDisclosureTemplateCommand,
} from "./apiClients";

const isPoBox = (text: string | undefined) => {
  if (!text) return false;
  return !!text.match(/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i);
};

export const passportSchema: yup.SchemaOf<PassportDto> = yup.object({
  passportNumber: yup.string(),
  expiryDate: yup.date(),
});

export const driverLicenceSchema: yup.SchemaOf<DriverLicenceDto> = yup.object({
  licenceNumber: yup.string(),
  versionNumber: yup.string(),
});

export const regoSchema: yup.SchemaOf<RegoDto> = yup.object({
  numberPlate: yup.string(),
});

const addressSchema: yup.SchemaOf<AddressDto> = yup.object({
  streetAddress: yup.string(),
  suburb: yup.string(),
  city: yup.string(),
  postCode: yup.string(),
  country: yup.string(),
});

export const baseOtherTaxCountrySchema: yup.SchemaOf<OtherTaxCountryDto> = yup.object({
  country: yup.string(),
  reason: yup.string(),
  tin: yup.string(),
});

const baseCountryResidedSchema: yup.SchemaOf<CountryResidedDto> = yup.object({
  country: yup.string(),
  yearsResided: yup.string(),
});

const baseClientSchema: yup.SchemaOf<ClientDto> = yup.object({
  hideKiwiSaver: yup.boolean(),
  hideWealthBuilder: yup.boolean(),
  hideIncomeGenerator: yup.boolean(),
  clientId: yup.string(),
  adviserEmail: yup.string(),
  adviserName: yup.string(),
  adviserNumber: yup.string(),
  adviserGroup: yup.string(),
  clientNumber: yup.string(),
  progress: yup.string(),
  salutation: yup.string(),
  firstName: yup.string(),
  middleName: yup.string(),
  lastName: yup.string(),
  irdNumber: yup.string(),
  dateOfBirth: yup.date(),
  occupation: yup.string(),
  mobile: yup.string(),
  email: yup.string(),
  countryOfBirth: yup.string(),
  citizenships: yup.array().of(yup.string()).default([]),
  residentialAddress: addressSchema.default(undefined),
  postalAddress: addressSchema.default(undefined),
  created: yup.date(),
  emailInvitationSent: yup.date(),
  smsInvitationSent: yup.date(),
  passport: passportSchema.default(undefined),
  driverLicence: driverLicenceSchema.default(undefined),
  rego: regoSchema.default(undefined),
  isNZTaxResident: yup.boolean(),
  pieTaxRate: yup.number(),
  taxIdentificationNumber: yup.string(),
  isOtherTaxResident: yup.boolean(),
  isUSPerson: yup.boolean(),
  otherTaxCountries: yup.array().of(baseOtherTaxCountrySchema.default(undefined)),
  countriesResided: yup.array().of(baseCountryResidedSchema.default(undefined)),
  businessInterestDetails: yup.string(),
  businessInterestOverseas: yup.boolean(),
  businessInterestInNewZealand: yup.boolean(),
  familyDetails: yup.string(),
  familyInNewZealand: yup.boolean(),
  familyOverseas: yup.boolean(),
  assetsOther: yup.string(),
  assetsInNewZealand: yup.boolean(),
  assetsOverseas: yup.boolean(),
  clientStatus: yup.mixed<ClientStatuses>(),
  adviceStatus: yup.mixed<AdviceStatuses>(),
  amlStatus: yup.mixed<AmlStatuses>(),
  annualIncome: yup.string(),
  bankAccountName: yup.string(),
  bankAccountNumber: yup.string(),
  estimatedTotalWealth: yup.string(),
  useNZFundsDisclosureTemplate: yup.boolean(),
  hasCloudcheckReference: yup.boolean(),
  hasLiveVerificationReference: yup.boolean(),
  applicationCompleted: yup.date(),
  confirmationType: yup.mixed<ConfirmationType>(),
  confirmationComment: yup.string(),
  isArchived: yup.boolean(),
  archivedDate: yup.date(),
  adviceType: yup.mixed<AdviceType>(),
  adviceDelivery: yup.mixed<AdviceDelivery>(),
  onboardDelivery: yup.mixed<OnboardDelivery>(),
  smsMessage: yup.string(),
  kiwiSaverApplication: yup.object({
    investmentOption: yup.mixed<InvestmentOptions>(),
    growth: yup.number(),
    inflation: yup.number(),
    income: yup.number(),
    created: yup.date(),
    isRetention: yup.boolean(),
    applicationClients: yup.lazy(() => yup.array().of(applicationClientSchema.default(undefined))),
  }),
  wealthBuilderApplications: yup.array().of(
    yup.object({
      bankAccountName: yup.string(),
      bankAccountNumber: yup.string(),
      sourceOfFunds: yup.string(),
      estimatedTotalWealth: yup.string(),
      investmentPurpose: yup.string(),
      investmentMethods: yup.string(),
      investmentOption: yup.mixed<InvestmentOptions>(),
      growth: yup.number(),
      inflation: yup.number(),
      income: yup.number(),
      created: yup.date(),
      applicationClients: yup.lazy(() => yup.array().of(applicationClientSchema.default(undefined))),
    })
  ),
  incomeGeneratorApplications: yup.array().of(
    yup.object({
      bankAccountName: yup.string(),
      bankAccountNumber: yup.string(),
      sourceOfFunds: yup.string(),
      estimatedTotalWealth: yup.string(),
      investmentPurpose: yup.string(),
      investmentMethods: yup.string(),
      distributionOption: yup.mixed<DistributionOptions>(),
      applicationClients: yup.lazy(() => yup.array().of(applicationClientSchema.default(undefined))),
    })
  ),
  clientApplicationSavedDate: yup.date(),
  prepopulated: yup.boolean(),
  sendEmail: yup.boolean(),
  sendSms: yup.boolean(),
  nccFailed: yup.boolean(),
  cloudCheckFailed: yup.boolean(),
  livenessFailed: yup.boolean(),
  ipFailed: yup.boolean(),
  products: yup.array().of(yup.mixed<Product>()).default([]),
  productOptions: yup.array().of(yup.mixed<Product>()).default([]),
});

const applicationClientSchema: yup.SchemaOf<ApplicationClientDto> = baseClientSchema.concat(
  yup.object({
    role: yup.mixed<ApplicationClientRoles>(),
    invitationSentDate: yup.date(),
  })
);

export const disclosureStatementSchema: yup.SchemaOf<UpsertDefautlDisclosureTemplateCommand> = yup.object({
  disclosureStatements: yup.array().of(
    yup.object({
      IsIncluded: yup.boolean(),
      content: yup.string().required("Content is required"),
      name: yup.string(),
    })
  ),
  removeLogo: yup.boolean(),
});

const residentialAddressSchema = addressSchema.default(undefined).when("isManualAddress", {
  is: false,
  then: addressSchema
    .default(undefined)
    .defined("Residential address is required")
    .test("residential-address", "This field must be a residential address", (value?: AddressDto) => !isPoBox(value?.streetAddress)),
  otherwise: yup
    .object({
      streetAddress: yup
        .string()
        .required("Street address is required")
        .test("residential-address-street", "This field must be a residential address", (value?: string) => !isPoBox(value)),
      suburb: yup.string(),
      city: yup.string().required("City is required"),
      postCode: yup.string().required("Postcode is required"),
      country: yup.string().required("Country is required"),
    })
    .default(undefined),
});

export const clientPersonalInfoSchema = baseClientSchema.concat(
  yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    salutation: yup.string().required("Title is required"),
    irdNumber: yup
      .string()
      .required("IRD number is required")
      .test("IRD valid", "IRD number is invalid", (value) => !!value && isValidIRDNumber(value)),
    dateOfBirth: yup
      .date()
      .required("Date of birth is required")
      .test("DOB valid", "Must be 100 years or less", (value) => !value || differenceInYears(new Date(), value || new Date()) <= 100)
      .when("kiwiSaverApplication", {
        is: (val?: KiwiSaverApplicationDto) => !!val?.active,
        then: yup.date().test("DOB valid", "Must be 18 years or over", (value) => differenceInYears(new Date(), value || new Date()) >= 18),
      })
      .when("kiwiSaverApplication", {
        is: (val?: KiwiSaverApplicationDto) => !val?.active,
        then: yup.date().test("DOB valid", "Must be 16 years or over", (value) => differenceInYears(new Date(), value || new Date()) >= 16),
      }),
    occupation: yup.string().required("Occupation is required"),
    mobile: yup.string().required("Mobile is required"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    countryOfBirth: yup.string().required("Country of birth is required"),
    citizenships: yup.array().of(yup.string().defined("Citizenship is required")).min(1, "Citizenship required"),
    isManualAddress: yup.boolean(),
    isManualPostalAddress: yup.boolean(),
    postalAddressDifferent: yup.boolean(),
    residentialAddress: residentialAddressSchema,
    postalAddress: addressSchema.default(undefined).when("postalAddressDifferent", {
      is: false,
      then: addressSchema.default(undefined),
      otherwise: addressSchema.default(undefined).when("isManualPostalAddress", {
        is: false,
        then: addressSchema.default(undefined).defined("Postal address is required"),
        otherwise: yup
          .object({
            streetAddress: yup.string().required("Street address is required"),
            suburb: yup.string(),
            city: yup.string().required("City is required"),
            postCode: yup.string().required("Postcode is required"),
            country: yup.string().required("Country is required"),
          })
          .default(undefined),
      }),
    }),
  })
);

export const validPassportSchema = yup.object({
  passportNumber: yup.string().matches(/^[A-Za-z]{1,2}[0-9]{6}$/, "Please enter a valid passport number"),
  expiryDate: yup.date().min(new Date(), "Expiry date must not be in the past"),
});

export const validDriverLicenceSchema = yup.object({
  licenceNumber: yup.string().matches(/^[A-Za-z]{2}[0-9]{6}$/, "Please enter a valid driver licence number"),
  versionNumber: yup.string().matches(/^[0-9]{3}$/, "Please enter a valid driver licence version number"),
});

export const kiwiSaverApplicationSchema = yup.object({
  isWithAnotherProvider: yup.boolean().required("An option is required"),
  isEligible: yup.boolean().when("isWithAnotherProvider", {
    is: false,
    then: yup.boolean().required("An option is required"),
    otherwise: yup.boolean(),
  }),
  investmentOption: yup.mixed<InvestmentOptions>().when(["isEligible", "isWithAnotherProvider"], {
    is: (isEligible: boolean | undefined, isWithAnotherProvider: boolean | undefined) => isWithAnotherProvider === false && isEligible === false,
    then: yup.mixed<InvestmentOptions>(),
    otherwise: yup.mixed<InvestmentOptions>().required("An investment option is required"),
  }),
});
