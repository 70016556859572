import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdviserDetail,
  AdviserDetails,
  ArchiveUnarchiveClientCommand,
  ClientDto,
  ConfirmClientCommand,
  CreateReferralCodeCommand,
  CreateReferralCodeResponse,
  DisclosureTemplateDto,
  FileParameter,
  GetKiwiSaverRetentionClientResponse,
  GetReferralCodesResponse,
  PreviewDisclosureStatementQuery,
  ProblemDetails,
  ReferClient,
  ResendEmailInvitationCommand,
  ResendSmsInvitationCommand,
  SearchClientsResponse,
  UpdateApplicantEmailMobileCommand,
  UpsertClientCommand,
  UpsertClientResponse,
  UpsertDefautlDisclosureTemplateCommand,
} from "../api";
import { downloadBlob } from "../common/helpers";
import { Client, ClientSearchParams } from "../common/types";
import { RootState } from "./";
import { getApis } from "./apiSelector";

interface adviserApplicationState {
  loading: boolean[];
  isLoading: boolean;
  isSubmitting: boolean;
  disclosureTemplate?: DisclosureTemplateDto;
  searchResults?: SearchClientsResponse;
  referralCodes?: GetReferralCodesResponse[];
  errors?: ProblemDetails;
  client?: Client;
  adviserDetails?: AdviserDetails;
  confirmClient?: ConfirmClientCommand;
  upsertClientResult?: UpsertClientResponse;
  createReferralCodeResult?: CreateReferralCodeResponse;
  message?: { text: string; error?: boolean };
  advisers?: AdviserDetail[];
}

const initialState: adviserApplicationState = {
  loading: [],
  isLoading: false,
  isSubmitting: false,
};

export const upsertClientAsync = createAsyncThunk<UpsertClientResponse, UpsertClientCommand, { state: RootState }>("adviser/upsertClient", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.upsertClient(payload).catch(rejectWithValue)
);

export const getDefaultDisclosureTemplateAsync = createAsyncThunk<DisclosureTemplateDto, void, { state: RootState }>("adviser/getDefaultDisclosureTemplate", (_, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.getDefaultDisclosureTemplate().catch(rejectWithValue)
);

export const upsertDefaultDisclosureTemplateAsync = createAsyncThunk<void, UpsertDefautlDisclosureTemplateCommand, { state: RootState }>(
  "adviser/upsertDefaultDisclosureTemplate",
  (payload, { getState, rejectWithValue, dispatch }) =>
    getApis(getState())
      .adviserClient.upsertDefaultDisclosureTemplate(payload)
      .then(async (_) => {
        await dispatch(getDefaultDisclosureTemplateAsync());
      })
      .catch(rejectWithValue)
);

export const searchClientsAsync = createAsyncThunk<SearchClientsResponse, ClientSearchParams, { state: RootState }>("adviser/searchClients", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.searchClients(payload.page, payload.query, payload.sortOrder, payload.filterByProgress, payload.filterByProducts).catch(rejectWithValue)
);

export const getReferralCodesAsync = createAsyncThunk<GetReferralCodesResponse[], void, { state: RootState }>("adviser/getReferralCodes", (_, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.getReferralCodes().catch(rejectWithValue)
);

export const getClientAsync = createAsyncThunk<ClientDto, string, { state: RootState }>("adviser/getClient", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.getClientById(payload).catch(rejectWithValue)
);

export const getKiwiSaverRetentionClientAsync = createAsyncThunk<GetKiwiSaverRetentionClientResponse, string, { state: RootState }>(
  "adviser/getKiwiSaverRetentionClient",
  (payload, { getState, rejectWithValue }) => getApis(getState()).adviserClient.getKiwiSaverRetentionClient(payload).catch(rejectWithValue)
);

export const resendEmailInvitationAsync = createAsyncThunk<void, ResendEmailInvitationCommand, { state: RootState }>("adviser/resendEmailInvitation", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.resendEmailInvitation(payload).catch(rejectWithValue)
);

export const resendSmsInvitationAsync = createAsyncThunk<void, ResendSmsInvitationCommand, { state: RootState }>("adviser/resendSmsInvitation", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.resendSmsInvitation(payload).catch(rejectWithValue)
);

export const getAdviceAgreementPDFAsync = createAsyncThunk<void, string, { state: RootState }>("adviser/getAdviceAgreementPDF", (payload: string, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.getAdviceAgreementPDF(payload)
    .then(({ data, fileName }) => downloadBlob(data, fileName || `Advice Agreement ${payload}.pdf`))
    .catch(rejectWithValue)
);

export const getVerificationPDFAsync = createAsyncThunk<void, string, { state: RootState }>("adviser/getVerificationPDF", (payload: string, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.getVerificationPDF(payload)
    .then(({ data, fileName }) => downloadBlob(data, fileName || `Verification ${payload}.pdf`))
    .catch(rejectWithValue)
);

export const getSavingsAdviceToolPdf = createAsyncThunk<void, string, { state: RootState }>("adviser/getSavingsAdviceToolPdf", (payload, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.getSavingsAdviceToolPdf(payload)
    .then(({ data, fileName }) => downloadBlob(data, fileName || `SAT ${payload}.pdf`))
    .catch(rejectWithValue)
);

export const getApplicationPdf = createAsyncThunk<void, string, { state: RootState }>("adviser/getApplicationPdf", (payload, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.getApplicationPdf(payload)
    .then(({ data, fileName }) => downloadBlob(data, fileName || `Application ${payload}.pdf`))
    .catch(rejectWithValue)
);

export const getDefaultDisclosureStatementPdfAsync = createAsyncThunk<void, void, { state: RootState }>("adviser/getDefaultDisclosureStatementPdf", (_, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.getDefaultDisclosureStatementPdf()
    .then(({ data, fileName }) => downloadBlob(data, fileName || `Disclosure Statement.pdf`))
    .catch(rejectWithValue)
);

export const previewDisclosureStatementAsync = createAsyncThunk<void, PreviewDisclosureStatementQuery, { state: RootState }>(
  "adviser/previewDisclosureStatement",
  (payload, { getState, rejectWithValue }) =>
    getApis(getState())
      .adviserClient.previewDisclosureStatement(payload)
      .then(({ data, fileName }) => downloadBlob(data, fileName || `Disclosure Statement.pdf`))
      .catch(rejectWithValue)
);

export const exportKiwiSaverApplicationCsv = createAsyncThunk<void, string, { state: RootState }>("adviser/exportKiwiSaverApplicationCsv", (payload, { getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.exportKiwiSaverApplicationCsv(payload)
    .then(({ data, fileName }) => downloadBlob(data, fileName || `KiwiSaver ${payload}.csv`))
    .catch(rejectWithValue)
);

export const getAdviserDetailsAsync = createAsyncThunk<AdviserDetails, void, { state: RootState }>("adviser/getAdviserDetails", (_, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.getAdviserDetails().catch(rejectWithValue)
);

export const getAdvisersAsync = createAsyncThunk<AdviserDetail[], void, { state: RootState }>("adviser/getAdvisers", (_, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.getAdvisers().catch(rejectWithValue)
);

export const referClientAsync = createAsyncThunk<void, ReferClient, { state: RootState }>("adviser/referClient", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.referClient(payload).catch(rejectWithValue)
);

export const confirmClientAsync = createAsyncThunk<void, ConfirmClientCommand, { state: RootState }>("adviser/confirmClient", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.confirmClient(payload).catch(rejectWithValue)
);

export const archiveUnarchiveClientAsync = createAsyncThunk<void, ArchiveUnarchiveClientCommand, { state: RootState }>("adviser/archiveUnarchiveClient", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.archiveUnarchiveClient(payload).catch(rejectWithValue)
);

export const createReferralCodeAsync = createAsyncThunk<CreateReferralCodeResponse, CreateReferralCodeCommand, { state: RootState }>(
  "adviser/createReferralCode",
  (payload, { getState, rejectWithValue }) => getApis(getState()).adviserClient.createReferralCode(payload).catch(rejectWithValue)
);

export const updateApplicantEmailMobileAsync = createAsyncThunk<void, UpdateApplicantEmailMobileCommand, { state: RootState }>(
  "adviser/updateApplicantEmailMobile",
  (payload, { getState, rejectWithValue }) => getApis(getState()).adviserClient.updateApplicantEmailMobile(payload).catch(rejectWithValue)
);

export const upsertDisclosureTemplateLogoAsync = createAsyncThunk<void, FileParameter, { state: RootState }>("adviser/upsertDisclosureTemplateLogo", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).adviserClient.upsertDisclosureTemplateLogo(payload).catch(rejectWithValue)
);

export const removeDisclosureTemplateLogoAsync = createAsyncThunk<void, void, { state: RootState }>("adviser/removeDisclosureTemplateLogo", (_, { dispatch, getState, rejectWithValue }) =>
  getApis(getState())
    .adviserClient.removeDisclosureTemplateLogo()
    .then(() => {
      dispatch(getDefaultDisclosureTemplateAsync());
    })
    .catch(rejectWithValue)
);

export const adviserApplicationSlice = createSlice({
  name: "adviser",
  initialState,
  reducers: {
    clearClient(state) {
      state.client = undefined;
      state.upsertClientResult = undefined;
    },
    clearUpsertClientResult(state) {
      state.upsertClientResult = undefined;
    },
    clearMessage(state) {
      state.message = undefined;
    },
    saveClient(state, action: PayloadAction<Client | undefined>) {
      state.client = action.payload;
    },
    setMessage(state, action: PayloadAction<{ text: string; error?: boolean }>) {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(upsertClientAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(upsertClientAsync.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.upsertClientResult = action.payload;
      })
      .addCase(upsertClientAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(createReferralCodeAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(createReferralCodeAsync.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.createReferralCodeResult = action.payload;
      })
      .addCase(createReferralCodeAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(upsertDefaultDisclosureTemplateAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(upsertDefaultDisclosureTemplateAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(upsertDefaultDisclosureTemplateAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(searchClientsAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(searchClientsAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.searchResults = action.payload;
      })
      .addCase(searchClientsAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getReferralCodesAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getReferralCodesAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.referralCodes = action.payload;
      })
      .addCase(getReferralCodesAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getClientAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getClientAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.client = action.payload;
      })
      .addCase(getClientAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getKiwiSaverRetentionClientAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getKiwiSaverRetentionClientAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getKiwiSaverRetentionClientAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.client = action.payload.client;
      })
      .addCase(resendEmailInvitationAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(resendEmailInvitationAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(resendEmailInvitationAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(resendSmsInvitationAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(resendSmsInvitationAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(resendSmsInvitationAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getDefaultDisclosureTemplateAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getDefaultDisclosureTemplateAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.disclosureTemplate = action.payload;
      })
      .addCase(getDefaultDisclosureTemplateAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.disclosureTemplate = undefined;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getAdviceAgreementPDFAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getAdviceAgreementPDFAsync.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(getAdviceAgreementPDFAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getVerificationPDFAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getVerificationPDFAsync.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(getVerificationPDFAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getSavingsAdviceToolPdf.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getSavingsAdviceToolPdf.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(getSavingsAdviceToolPdf.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getApplicationPdf.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getApplicationPdf.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(getApplicationPdf.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getDefaultDisclosureStatementPdfAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getDefaultDisclosureStatementPdfAsync.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(getDefaultDisclosureStatementPdfAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(previewDisclosureStatementAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(previewDisclosureStatementAsync.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(previewDisclosureStatementAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getAdviserDetailsAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getAdviserDetailsAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.adviserDetails = action.payload;
      })
      .addCase(getAdviserDetailsAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(referClientAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(referClientAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(referClientAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(confirmClientAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(confirmClientAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(confirmClientAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(archiveUnarchiveClientAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(archiveUnarchiveClientAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(archiveUnarchiveClientAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(updateApplicantEmailMobileAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(updateApplicantEmailMobileAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(updateApplicantEmailMobileAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(upsertDisclosureTemplateLogoAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(upsertDisclosureTemplateLogoAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(upsertDisclosureTemplateLogoAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(removeDisclosureTemplateLogoAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(removeDisclosureTemplateLogoAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(removeDisclosureTemplateLogoAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(exportKiwiSaverApplicationCsv.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
      })
      .addCase(exportKiwiSaverApplicationCsv.fulfilled, (state) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
      })
      .addCase(exportKiwiSaverApplicationCsv.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getAdvisersAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getAdvisersAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.advisers = action.payload;
      })
      .addCase(getAdvisersAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      });
  },
});

export const { clearClient, clearUpsertClientResult, clearMessage, saveClient, setMessage } = adviserApplicationSlice.actions;
export default adviserApplicationSlice.reducer;
