import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Colors } from '.';
  
const BurgerMenuIcon = ({ menuOpen }: { menuOpen?: boolean }) => {
    var styles = useStyles();

    return <div className={clsx(styles.navIcon, menuOpen && styles.menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
    </div>
}

const useStyles = makeStyles({
    navIcon: {
        width: 20,
        height: 15,
        position: 'relative',
        margin: '0',
        transform: 'rotate(0deg)',
        transition: '.5s ease-in-out',
        cursor: 'pointer',

        '& span': {
            display: 'block',
            position: 'absolute',
            height: 2,
            width: '100%',
            background: Colors.White,
            borderRadius: 3,
            opacity: 1,
            left: 0,
            transform: 'rotate(0deg)',
            transition: '.15s ease-in-out',

            '&:nth-child(1)': {
                top: 0,
                '$menuOpen&': {
                    top: 6,
                    transform: 'rotate(135deg)'
                }
            },
            '&:nth-child(2)': {
                top: 6,
                '$menuOpen&': {
                    opacity: 0,
                    left: '-20px'
                }
            },
            '&:nth-child(3)': {
                top: 12,
                '$menuOpen&': {
                    top: 6,
                    transform: 'rotate(-135deg)'
                }
            },
        },
    },
    menuOpen: {}
}, { name: 'BurgerMenuIcon' });

export default BurgerMenuIcon;
