import { createUserManager } from 'redux-oidc';

export const portUserManager = createUserManager({
  authority: process.env.REACT_APP_PORT_URL,
  client_id: 'nzfunds.port',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/adviser/authentication/callback`,
  response_type: 'code',
  scope: 'openid profile roles offline_access nzfunds.clientonboarding investment.api.public auth.api.public',
  automaticSilentRenew: true,
  monitorSession: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
});
