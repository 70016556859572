import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { ReactNode } from "react";
import { Colors } from "./";

interface TextProps {
  bold?: boolean;
  children: ReactNode;
  className?: string;
  hideMarginBottom?: boolean;
  variant?: Variant;
}

export default function TitleText({ bold, children, className, hideMarginBottom, variant = "h4" }: TextProps) {
  return (
    <Typography className={className} style={{ color: Colors.Black, fontWeight: bold ? 500 : 400, marginBottom: hideMarginBottom ? 3 : 24 }} variant={variant}>
      {children}
    </Typography>
  );
}
