import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ProblemDetails, CalculateBalanceQuery, CalculateBalanceResponse } from '../api';
import { getApis } from './apiSelector';

interface KiwiSaverState {
    isLoading: boolean;
    calculateBalances: CalculateBalanceResponse[];
    errors?: ProblemDetails;
}

const initialState: KiwiSaverState = {
    isLoading: false,
    calculateBalances: []
};

export const calculateBalanceAsync = createAsyncThunk<CalculateBalanceResponse[], CalculateBalanceQuery[], { state: RootState }>('calculate/calculateBalance', (payloads, { getState, rejectWithValue }) =>
    Promise.all(payloads.map(payload => getApis(getState()).calculateClient.calculate(payload)))
        .then(result => result)
        .catch(rejectWithValue)
);

export const calculateSlice = createSlice({
    name: 'calculate',
    initialState,
    reducers: {
        clearCalculateBalances(state) {
            state.calculateBalances = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(calculateBalanceAsync.pending, (state) => {
                state.isLoading = true;
                state.errors = undefined;
            })
            .addCase(calculateBalanceAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.calculateBalances = action.payload;
            })
            .addCase(calculateBalanceAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.errors = action.payload as ProblemDetails;
            });
    },
});

export const { clearCalculateBalances } = calculateSlice.actions;
export default calculateSlice.reducer;
