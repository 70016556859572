import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (Object.keys(errors).length > 0 && isSubmitting && !isValidating) {
            setTimeout(() => {
                const errorElement = document.getElementsByClassName('Mui-error')[0];
                if (errorElement) errorElement.parentElement!.scrollIntoView({ behavior: 'smooth' })
            }, 150);
        }
    }, [errors, isSubmitting, isValidating])

    return null;
}
