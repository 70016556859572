import { FunctionComponent, ReactNode, SVGProps } from "react";
import {
  AdviceDelivery,
  ApplicationClientDto,
  DistributionOptions,
  IncomeGeneratorApplicationDto,
  InvestmentOptions,
  Product,
  ProductFilterOptions,
  ProgressFilterOptions,
  SearchClientsSortOptions,
  UpsertClientCommand,
  WealthBuilderApplicationDto,
} from "../api";
import { ListOption } from "../components/controls/inputs/ListBase";

export const FLAG_SKIP_NCC_SCREEN = "ClientOnboarding/SkipNccScreen";

export enum SendMethod {
  None,
  Email,
  Sms,
  EmailAndSms,
}

export enum YesNoOption {
  Yes = "Yes",
  No = "No",
}

export enum Application {
  KiwiSaver,
  IncomeGenerator,
  WealthBuilder,
}

export interface SendApplicationToClientResult {
  adviceDelivery?: AdviceDelivery;
  sendEmail?: boolean;
  sendSms?: boolean;
  useNZFundsDisclosureTemplate?: boolean;
}

export interface ClientSearchParams {
  filterByProducts: ProductFilterOptions;
  filterByProgress: ProgressFilterOptions;
  page: number;
  query: string;
  sortOrder: SearchClientsSortOptions;
}

export interface ScreenProps {
  header: ReactNode | ((values: any) => ReactNode);
  HeaderIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: ReactNode | ((values: any) => ReactNode);
  subtitle?: ReactNode | ((values: any) => ReactNode);
  route: string;
  nextLabel?: string;
  generateRoute?: (route: string, back: boolean, values: any, index?: number) => string;
  skipRoute?: (values: any) => boolean | undefined;
  prevRoute?: (values: any) => void;
  nextRoute: (values: any) => void;
  inBrowserApp?: boolean;
}

export interface RouteScreen extends ScreenProps {
  Component: any;
}

export interface ApplicationClient extends ApplicationClientDto {
  new?: boolean;
  overMinAge?: boolean;
}

export interface WealthBuilderApplication extends WealthBuilderApplicationDto {
  applicationClients?: ApplicationClient[];
}

export interface IncomeGeneratorApplication extends IncomeGeneratorApplicationDto {
  applicationClients?: ApplicationClient[];
}

export interface Client extends UpsertClientCommand {
  isManualAddress?: boolean;
  isManualPostalAddress?: boolean;
  postalAddressDifferent?: boolean;
  consented?: boolean;
  identificationOption?: string;
  pdsDownloaded?: boolean;
  wealthBuilderApplications?: WealthBuilderApplication[];
  incomeGeneratorApplications?: IncomeGeneratorApplication[];
}

export const riskOptions = {
  low: `Very worried, I'm a cautious investor`,
  med: `A little worried, I'm human`,
  high: `Not worried, I understand it's a long term investment`,
};

export const withdrawTimeframes = [
  { years: 1, label: "1 year" },
  { years: 2, label: "2 years" },
  { years: 3, label: "3 years" },
  { years: 4, label: "4 years" },
  { years: 5, label: "5 years" },
];

export const supportRoles = ["KiwiSaver Support", "WealthBuilder Support", "IncomeGenerator Support"];

export const adviserProductRoles = ["KiwiSaver", "WealthBuilder", "IncomeGenerator"];

export const yearsResided = ["1", "2", "3", "4", "5–10", "11–20", "20+"];

export const bankAccountNonPersonalTerms = ["Trust", "Limited", "Ltd", "Westpac", "BNZ", "ANZ", "ASB", "Savings", "Cheque", "Credit"].map((t) => t.toLocaleLowerCase());

export const yesNoOptions = [
  { label: YesNoOption.Yes, value: true },
  { label: YesNoOption.No, value: false },
];

export const titles = [
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Miss", value: "Miss" },
  { label: "Dr", value: "Doctor" },
];

export const pieTaxRates = [
  {
    value: 0.105,
    label: "10.5%",
    incomeBrackets: ["$0 – $14,000", "$0 – $48,000"],
  },
  {
    value: 0.175,
    label: "17.5%",
    incomeBrackets: ["$0 – $14,000", "$48,001 – $70,000", "$14,001 – $48,000", "$0 – $70,000"],
  },
  {
    value: 0.28,
    label: "28%",
    incomeBrackets: ["Over $48,000", "Any amount", "Any amount", "Over $70,000"],
  },
];

export const productDetails: Record<
  Product,
  {
    name: string;
    value: Product;
    minAge: number;
    applicationKey: string;
    info: string;
  }
> = {
  [Product.KiwiSaver]: {
    name: "KiwiSaver",
    value: Product.KiwiSaver,
    minAge: 18,
    applicationKey: "kiwiSaverApplication",
    info: "KiwiSaver is a voluntary retirement savings scheme to help you save for your future.",
  },
  [Product.IncomeGenerator]: {
    name: "Income Generator",
    value: Product.IncomeGenerator,
    minAge: 16,
    applicationKey: "incomeGeneratorApplications",
    info: "Income Generator offers higher returns with reduced volatility. It is a portfolio of dividend paying shares that passes dividends on to you as income.",
  },
  [Product.WealthBuilder]: {
    name: "Wealth Builder",
    value: Product.WealthBuilder,
    minAge: 16,
    applicationKey: "wealthBuilderApplications",
    info: "Wealth Builder is just like KiwiSaver but without the lock-in. Use it to boost your savings contribution rate.",
  },
};

export const investmentPurposeOptions = [
  {
    value: "1",
    label: "Pre retirement, saving for retirement",
  },
  {
    value: "2",
    label: "Post retirement, income in retirement",
  },
  {
    value: "3",
    label: "Saving for near term purchase",
  },
  {
    value: "4",
    label: "Wealth accumulation",
  },
  {
    value: "",
    label: "Other",
  },
];

export const investmentMethodOptions = [
  {
    value: "1",
    label: "Initial lump sum only",
  },
  {
    value: "2",
    label: "Lump sums",
  },
  {
    value: "3",
    label: "Regular contributions",
  },
  {
    value: "4",
    label: "Mixed lump sum(s) and regular contributions",
  },
];

export const distributionOptionNames: Record<DistributionOptions, string> = {
  [DistributionOptions.PaidOut]: "Paid out",
  [DistributionOptions.Reinvest]: "Reinvest",
};

export const distributionOptionOptions = [
  {
    value: DistributionOptions.PaidOut,
    label: distributionOptionNames[DistributionOptions.PaidOut],
    info: `I'd like my distributions to be paid out.`,
  },
  {
    value: DistributionOptions.Reinvest,
    label: distributionOptionNames[DistributionOptions.Reinvest],
    info: `I'd like my distributions to be reinvested.`,
  },
];

export const investmentOptionNames: Record<InvestmentOptions, string> = {
  [InvestmentOptions.LifeCycle]: "Life Cycle",
  [InvestmentOptions.SelfSelect]: "Self Select",
  [InvestmentOptions.Balanced]: "Balanced",
};

export const investmentOptionOptions = ({ kiwiSaver, showBalancedOption }: { kiwiSaver?: boolean; showBalancedOption?: boolean }): ListOption<InvestmentOptions>[] => [
  {
    value: InvestmentOptions.LifeCycle,
    label: investmentOptionNames[InvestmentOptions.LifeCycle],
    info:
      "Your savings are automatically allocated across the Strategies based on your age." +
      (kiwiSaver ? "" : " For a joint account, age allocation will be based on the person with the oldest age."),
  },
  {
    value: InvestmentOptions.SelfSelect,
    label: investmentOptionNames[InvestmentOptions.SelfSelect],
    info: "Self Select your own investment mix by selecting which Strategy or Strategies you want to invest in, and the proportion to be invested in each Strategy.",
  },
  ...(showBalancedOption
    ? [
        {
          value: InvestmentOptions.Balanced,
          label: investmentOptionNames[InvestmentOptions.Balanced],
          info: "Your savings will be invested in a single fund holding a balanced mix of income and growth assets and using a passive investment approach.",
        },
      ]
    : []),
];

export const incomeBands = [
  {
    value: "1",
    label: "$0 – $24,999",
  },
  {
    value: "2",
    label: "$25,000 – $49,999",
  },
  {
    value: "3",
    label: "$50,000 – $99,999",
  },
  {
    value: "4",
    label: "$100,000 – $199,999",
  },
  {
    value: "5",
    label: "$200,000+",
  },
];

export const wealthBands = [
  {
    value: "12",
    label: "$0 – $4,999",
  },
  {
    value: "13",
    label: "$5,000 – $49,999",
  },
  {
    value: "7",
    label: "$50,000 – $199,999",
  },
  {
    value: "8",
    label: "$200,000 – $499,999",
  },
  {
    value: "9",
    label: "$500,000 – $999,999",
  },
  {
    value: "10",
    label: "$1,000,000 – $2,999,999",
  },
  {
    value: "11",
    label: "$3,000,000+",
  },
];

export const esctTaxBands = [
  {
    UpTo: 16800,
    TaxRate: 0.105,
  },
  {
    UpTo: 57600,
    TaxRate: 0.175,
  },
  {
    UpTo: 84000,
    TaxRate: 0.3,
  },
  {
    UpTo: 216000,
    TaxRate: 0.33,
  },
  {
    UpTo: Number.MAX_VALUE,
    TaxRate: 0.39,
  },
];

export const countries = [
  "New Zealand",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central Arfrican Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauro",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre & Miquelon",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "St Kitts & Nevis",
  "St Lucia",
  "St Vincent",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  `Timor L'Este`,
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
