import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { loadUser, reducer as oidc } from "redux-oidc";
import { portUserManager } from "../api";
import addressFinder from "./addressFinder";
import adviser from "./adviser";
import calculate from "./calculate";
import clientApplication from "./clientApplication";
import clientQuestionnaires from "./clientQuestionnaires";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
export const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore({
  reducer: {
    oidc,
    addressFinder,
    adviser,
    clientQuestionnaires,
    clientApplication,
    calculate,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

loadUser(store, portUserManager);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
