import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const Adviser = lazy(() => import("./components/adviser"));
const Client = lazy(() => import("./components/client"));
const Invite = lazy(() => import("./components/invite"));
const KiwiSaverCalculator = lazy(() => import("./components/kiwiSaverCalculator"));

export default function App() {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/adviser" component={Adviser} />
        <Route path="/client" component={Client} />
        <Route path="/invite" component={Invite} />
        <Route path="/kiwisaver-calculator" component={KiwiSaverCalculator} />
        <Route>
          <Redirect to="/adviser/new-application" />
        </Route>
      </Switch>
    </Suspense>
  );
}
