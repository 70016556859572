import { useField, useFormikContext, FieldHookConfig, FieldInputProps, FieldMetaProps, FieldHelperProps, isInteger, getIn, isObject } from 'formik';
import { clone, toPath } from 'lodash';

export const useContextField = <Val = any>(props: string | FieldHookConfig<Val>): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] => {
    const [field, meta, helper] = useField(props);
    const { setFieldValue, setValues, values } = useFormikContext();

    helper.setValue = (value: Val, shouldValidate?: boolean) => {
        if (value === undefined) {
            setValues(setIn(values, field.name, value), shouldValidate);
            helper.setTouched(true);
        } else {
            // Use default behavior for normal values
            setFieldValue(field.name, value, shouldValidate);
        }
    };
    return [field, meta, helper];
}

const setIn = (obj: any, path: string, value: any) => {
    let res: any = clone(obj); // this keeps inheritance when obj is a class
    let resVal: any = res;
    let i = 0;
    let pathArray = toPath(path);

    for (; i < pathArray.length - 1; i++) {
        const currentPath: string = pathArray[i];
        let currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

        if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
            resVal = resVal[currentPath] = clone(currentObj);
        } else {
            const nextPath: string = pathArray[i + 1];
            resVal = resVal[currentPath] = isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
        }
    }

    if ((i === 0 ? obj : resVal)[pathArray[i]] === value)
        return obj;

    resVal[pathArray[i]] = value;
    return res;
}
