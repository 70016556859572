import Typography from '@material-ui/core/Typography';
import { Colors } from '.';

interface TextProps {
    children: any;
    black?: boolean;
    hideMarginBottom?: boolean;
}

export default (props: TextProps) => {
    const { black, children, hideMarginBottom } = props;
    return <Typography variant="subtitle1" style={{ marginBottom: hideMarginBottom ? 3 : 24, color: black ? Colors.Black : Colors.DarkGrey }}>{children}</Typography>
}
