import { withStyles, createStyles, Tab, TabProps } from '@material-ui/core';
import { Colors } from '.';
 
export default withStyles((_) =>
    createStyles({
        root: {
            fontSize: '0.875rem',
            flexGrow: 0,
            color: Colors.DarkGrey,
            opacity: 1,
            textTransform: 'none',
            fontWeight: 500,
            '&.Mui-selected': {
                color: Colors.Blue
            },
            '& span': {
                zIndex: 2
            },
            '@media(max-width: 768px)': {
                flexGrow: 1
            }
        },
        wrapper: {
            flexDirection: 'row-reverse'
        },
        labelIcon: {
            minHeight: 50,
            '& :first-child': {
                marginBottom: '0 !important'
            }
        }
    })
)((props: TabProps) => <Tab {...props} />);
