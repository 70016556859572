import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { ClientClient, QuestionnaireClient, AdviserClient, AddressFinderClient, CalculateClient } from '../api';

const getAccessToken = (state: any) => state.oidc.user;

export const getApis = createDraftSafeSelector(getAccessToken, user => {
    const http = (url: RequestInfo, init?: RequestInit) => {
        const token = user?.access_token;
        if (token && init) init.headers = { ...init.headers, Authorization: `Bearer ${token}` };
        return fetch(url, init);
    };

    return {
        questionnaireClient: new QuestionnaireClient(
            process.env.REACT_APP_API_URL,
            { fetch: http }
        ),
        calculateClient: new CalculateClient(
            process.env.REACT_APP_API_URL,
            { fetch: http }
        ),
        addressFinderClient: new AddressFinderClient(
            process.env.REACT_APP_API_URL,
            { fetch: http }
        ),
        clientClient: new ClientClient(process.env.REACT_APP_API_URL, {
            fetch: http,
        }),
        adviserClient: new AdviserClient(process.env.REACT_APP_API_URL, {
            fetch: http,
        })
    };
});
