import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientDto, CreateFromReferralCodeCommand, FinaliseClientCommand, ProblemDetails, SendPdsCommand } from "../api";
import { downloadBlob } from "../common/helpers";
import { RootState } from "./";
import { getApis } from "./apiSelector";

export interface ClientApplicationState {
  isLoading: boolean;
  loading: boolean[];
  isSubmitting: boolean;
  pdsDownloading: boolean;
  pdsDownloaded: boolean;
  client?: ClientDto;
  nccList?: string[];
  errors?: ProblemDetails;
  clientId?: string;
  pdsEmailSending?: boolean;
  pdsEmailSent?: boolean;
  message?: string;
  url?: string;
}

const initialState: ClientApplicationState = {
  loading: [],
  isLoading: false,
  isSubmitting: false,
  pdsDownloading: false,
  pdsDownloaded: false,
  pdsEmailSending: false,
  pdsEmailSent: false,
};

export const getAsync = createAsyncThunk<ClientDto, string, { state: RootState }>("clientApplication/get", (payload: string, { getState, rejectWithValue }) =>
  getApis(getState()).clientClient.get(payload).catch(rejectWithValue)
);

export const updateAsync = createAsyncThunk<void, { token: string; client: ClientDto }, { state: RootState }>("clientApplication/update", (payload, { getState, dispatch, rejectWithValue }) =>
  getApis(getState())
    .clientClient.update(payload.token, payload.client)
    .then((_) => {
      dispatch(getAsync(payload.token));
    })
    .catch(rejectWithValue)
);

export const getNccAsync = createAsyncThunk<string[], void, { state: RootState }>("clientApplication/getNcc", (_, { getState, rejectWithValue }) =>
  getApis(getState()).clientClient.getNonCompliantCountries().catch(rejectWithValue)
);

export const createFromReferralCodeAsync = createAsyncThunk<string, CreateFromReferralCodeCommand, { state: RootState }>(
  "clientApplication/createFromReferralCode",
  (payload, { getState, rejectWithValue }) => getApis(getState()).clientClient.createFromReferralCode(payload).catch(rejectWithValue)
);

export const finaliseAsync = createAsyncThunk<void, FinaliseClientCommand, { state: RootState }>("clientApplication/finalise", (payload, { getState, dispatch, rejectWithValue }) =>
  getApis(getState())
    .clientClient.finalise(payload)
    .then(() => {
      dispatch(getAsync(payload.token!));
    })
    .catch(rejectWithValue)
);

export const downloadPdsAsync = createAsyncThunk<void, { includeKiwiSaver: boolean; includeWealthBuilder: boolean; includeIncomeGenerator: boolean }, { state: RootState }>(
  "clientApplication/downloadPds",
  (payload, { getState, rejectWithValue }) =>
    getApis(getState())
      .clientClient.getCombinedPds(payload.includeKiwiSaver, payload.includeWealthBuilder, payload.includeIncomeGenerator)
      .then(({ data, fileName }) => downloadBlob(data, fileName || "NZ Funds Product Disclosure Statements.pdf"))
      .catch(rejectWithValue)
);

export const sendPdsEmailAsync = createAsyncThunk<void, SendPdsCommand, { state: RootState }>("clientApplication/sendPdsEmail", (payload, { getState, rejectWithValue }) =>
  getApis(getState()).clientClient.sendPdsEmail(payload).catch(rejectWithValue)
);

export const clientApplicationSlice = createSlice({
  name: "clientApplication",
  initialState,
  reducers: {
    resetPdsEmailState(state) {
      state.pdsEmailSending = false;
      state.pdsEmailSent = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.client = action.payload;
      })
      .addCase(getAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(getNccAsync.pending, (state) => {
        state.loading.push(true);
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getNccAsync.fulfilled, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.nccList = action.payload;
      })
      .addCase(getNccAsync.rejected, (state, action) => {
        state.loading.pop();
        state.isLoading = !!state.loading.length;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(updateAsync.pending, (state) => {
        state.isSubmitting = true;
        state.pdsDownloaded = false;
        state.errors = undefined;
      })
      .addCase(updateAsync.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(updateAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(downloadPdsAsync.pending, (state) => {
        state.pdsDownloading = true;
        state.pdsDownloaded = false;
        state.errors = undefined;
      })
      .addCase(downloadPdsAsync.fulfilled, (state) => {
        state.pdsDownloading = false;
        state.pdsDownloaded = true;
      })
      .addCase(downloadPdsAsync.rejected, (state, action) => {
        state.pdsDownloading = false;
        state.pdsDownloaded = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(finaliseAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(finaliseAsync.fulfilled, (state, action) => {
        state.isSubmitting = false;
      })
      .addCase(finaliseAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(createFromReferralCodeAsync.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(createFromReferralCodeAsync.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.clientId = action.payload;
      })
      .addCase(createFromReferralCodeAsync.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      })
      .addCase(sendPdsEmailAsync.pending, (state) => {
        state.pdsEmailSending = true;
        state.pdsEmailSent = false;
        state.errors = undefined;
      })
      .addCase(sendPdsEmailAsync.fulfilled, (state) => {
        state.pdsEmailSending = false;
        state.pdsEmailSent = true;
      })
      .addCase(sendPdsEmailAsync.rejected, (state, action) => {
        state.pdsEmailSending = false;
        state.pdsEmailSent = false;
        state.errors = action.payload as ProblemDetails;
      });
  },
});

export const { resetPdsEmailState } = clientApplicationSlice.actions;
export default clientApplicationSlice.reducer;
