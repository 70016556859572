import { Application } from "../common/types";
import { useAppSelector } from "../store";

export default function useApplications() {
  const { responses, incomeGenerator, kiwisaver, wealthBuilder } = useAppSelector((state) => state.clientQuestionnaires);
  const applications = [];

  if (kiwisaver) applications.push(Application.KiwiSaver);
  if (wealthBuilder) applications.push(Application.WealthBuilder);
  if (incomeGenerator) applications.push(Application.IncomeGenerator);
  if (responses && applications.length === 0) return [Application.KiwiSaver, Application.WealthBuilder, Application.IncomeGenerator];
  return applications;
}
