import { differenceInCalendarYears } from "date-fns";
import { ApplicationClientDto, ApplicationClientRoles, ApplicationDto, ClientDto, IncomeGeneratorApplicationDto, KiwiSaverApplicationDto, Product, WealthBuilderApplicationDto } from "../api";
import useApplications from "../hooks/useApplications";
import { Application, bankAccountNonPersonalTerms, productDetails } from "./types";

export const getAge = (client: ClientDto) => differenceInCalendarYears(Date.now(), new Date(client?.dateOfBirth || Date.now()));

export const getProducts = (client: ClientDto, options?: { checkAge?: boolean; checkHidden?: boolean }): Product[] => {
  const age = options?.checkAge ? getAge(client) : undefined;
  return [
    ...(client.kiwiSaverApplication?.active && (age === undefined || productDetails.KiwiSaver.minAge <= age) && (!options?.checkHidden || !client.hideKiwiSaver) ? [Product.KiwiSaver] : []),
    ...(client.wealthBuilderApplications?.some((a) => a.active) && (age === undefined || productDetails.WealthBuilder.minAge <= age) ? [Product.WealthBuilder] : []),
    ...(client.incomeGeneratorApplications?.some((a) => a.active) && (age === undefined || productDetails.IncomeGenerator.minAge <= age) ? [Product.IncomeGenerator] : []),
  ];
};

export const getApplications = (client: ClientDto): (ApplicationDto & { product: Product })[] => [
  ...(client.kiwiSaverApplication?.active ? [{ product: Product.KiwiSaver, ...client.kiwiSaverApplication }] : []),
  ...(client.wealthBuilderApplications?.some((a) => a.active) ? client.wealthBuilderApplications.filter((a) => a.active).map((app) => ({ product: Product.WealthBuilder, ...app })) : []),
  ...(client.incomeGeneratorApplications?.some((a) => a.active) ? client.incomeGeneratorApplications.filter((a) => a.active).map((app) => ({ product: Product.IncomeGenerator, ...app })) : []),
];

export const initialValuesApplicationDefaults = <T extends (KiwiSaverApplicationDto & WealthBuilderApplicationDto & IncomeGeneratorApplicationDto & { isJoint?: boolean }) | undefined>(
  application: T
): KiwiSaverApplicationDto & WealthBuilderApplicationDto & IncomeGeneratorApplicationDto & { totalAllocated?: number } & { isJoint?: boolean } => {
  if (!application)
    return {
      nickname: "",
      annualIncome: undefined,
      estimatedTotalWealth: undefined,
      investmentOption: undefined,
      income: undefined,
      inflation: undefined,
      growth: undefined,
      totalAllocated: 0,
      isJoint: undefined,
    };
  return {
    nickname: "",
    annualIncome: undefined,
    estimatedTotalWealth: undefined,
    investmentOption: undefined,
    investmentPurpose: undefined,
    investmentMethods: undefined,
    distributionOption: undefined,
    income: undefined,
    inflation: undefined,
    growth: undefined,
    totalAllocated: undefined,
    isJoint: undefined,
    ...(application && application),
  };
};

const isJointApplications = (clientId: string | undefined, applications: ApplicationDto[]): boolean =>
  applications.some((a) => a.applicationClients?.some((c) => c.clientId === clientId && c.role === ApplicationClientRoles.Joint));

export const isJointApplicationClient = (client: ClientDto): boolean => isJointApplications(client.clientId, getApplications(client));

const getApplicationClients = (client: ClientDto): ApplicationClientDto[] => {
  const applications = getApplications(client);
  const applicationClients = applications.map((a) => a.applicationClients || []);
  return applicationClients.length > 0 ? applicationClients.reduce((a, b) => a.concat(b)) : [];
};

export const getPrimaryClient = (client: ClientDto | undefined): ClientDto | undefined => {
  if (!client) return;
  return getApplicationClients(client).find((ac) => ac.role === ApplicationClientRoles.Primary);
};

export const usePrimarySatProduct = (): Product => {
  const applications = useApplications();
  if (applications.includes(Application.KiwiSaver)) return Product.KiwiSaver;
  else if (applications.includes(Application.WealthBuilder)) return Product.WealthBuilder;
  else return Product.IncomeGenerator;
};

export const bankAccountHasNonPersonalTerms = (accountName: string | undefined, lastName: string | undefined) => {
  if (!accountName || !lastName) return true;
  const _text = accountName.toLocaleLowerCase();
  if (!_text.includes(lastName.toLocaleLowerCase())) return true;
  return bankAccountNonPersonalTerms.some((t) => _text.includes(t));
};

export const isBnzBankAccount = (accountNumber?: string) => {
  const accountNumberSegments = accountNumber?.split("-") || [];
  return !!accountNumberSegments.length && accountNumberSegments[0] === "02"; // 02 = BNZ - BNZ acounts are required to go thru Liveness unless they have LiveVerificationSkipped AML status
};

export const getClientDisplayName = (client: ClientDto) => (!client.firstName && !client.lastName ? client.email : `${client.firstName} ${client.lastName || ""}`);

/**
 * Provides a mechanism for downloading files on the web.
 * @param blob File data.
 * @param fileName File name.
 */
export function downloadBlob(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const link = window.document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}
