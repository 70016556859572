import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import {
    AdviceDelivery,
    CompleteAdviceCommand,
    CompleteAdviceResponse,
    GetQuestionnaireByReferralCodeResponse,
    GetQuestionnaireResponsesByClientIdResponse,
    ProblemDetails,
    SubmitQuestionnaireResponseCommand
} from '../api';
import { getApis } from './apiSelector';

interface ClientQuestionnairesState {
    loading: boolean[];
    isLoading?: boolean;
    isSubmitting: boolean;
    incomeGenerator?: boolean;
    kiwisaver?: boolean;
    wealthBuilder?: boolean;
    responses?: { [key: string]: string };
    errors?: ProblemDetails;
    skipSAT?: boolean;
    adviserName?: string;
    adviserGroup?: string;
    clientFirstName?: string;
    openBrowserApplication?: boolean;
    url?: string;
    adviceDelivery?: AdviceDelivery;
}

const initialState: ClientQuestionnairesState = {
    loading: [],
    isSubmitting: false,
    incomeGenerator: false,
    kiwisaver: false,
    wealthBuilder: false,
    skipSAT: false
};

export const getByReferralCodeAsync = createAsyncThunk<GetQuestionnaireByReferralCodeResponse, string, { state: RootState }>(
    'clientQuestionnaires/getByReferralCode', (referralCode: string, { getState, rejectWithValue }) => getApis(getState()).questionnaireClient.getByReferralCode(referralCode).catch(rejectWithValue)
);

export const getAsync = createAsyncThunk<GetQuestionnaireResponsesByClientIdResponse, string, { state: RootState }>(
    'clientQuestionnaires/get', (clientId: string, { getState, rejectWithValue }) => getApis(getState()).questionnaireClient.get(clientId).catch(rejectWithValue)
);

export const upsertAsync = createAsyncThunk<void, SubmitQuestionnaireResponseCommand, { state: RootState }>(
    'clientQuestionnaires/upsert', (payload: SubmitQuestionnaireResponseCommand, { getState, rejectWithValue }) => getApis(getState()).questionnaireClient.upsert(payload).catch(rejectWithValue)
);

export const completeAsync = createAsyncThunk<CompleteAdviceResponse, CompleteAdviceCommand, { state: RootState }>(
    'clientQuestionnaires/complete', (payload: CompleteAdviceCommand, { getState, rejectWithValue }) => getApis(getState()).questionnaireClient.completeAdvice(payload).catch(rejectWithValue)
);

export const clientQuestionnairesSlice = createSlice({
    name: 'clientQuestionnaires',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAsync.pending, (state) => {
                state.loading.push(true);
                state.isLoading = true;
                state.errors = undefined;
            })
            .addCase(getAsync.fulfilled, (state, { payload }) => {
                state.loading.pop();
                state.isLoading = !!state.loading.length;
                state.adviserName = payload.adviserName;
                state.adviserGroup = payload.adviserGroup;
                state.responses = payload.questionnaireResponses;
                state.kiwisaver = payload.kiwisaver;
                state.incomeGenerator = payload.incomeGenerator;
                state.wealthBuilder = payload.wealthBuilder;
                state.skipSAT = payload.skipSAT;
                state.clientFirstName = payload.clientFirstName;
                state.openBrowserApplication = payload.openBrowserApplication;
                state.adviceDelivery = payload.adviceDelivery;
            })
            .addCase(getAsync.rejected, (state, action) => {
                state.loading.pop();
                state.isLoading = !!state.loading.length;
                state.errors = action.payload as ProblemDetails;
            })
            .addCase(getByReferralCodeAsync.pending, (state) => {
                state.loading.push(true);
                state.isLoading = true;
                state.errors = undefined;
            })
            .addCase(getByReferralCodeAsync.fulfilled, (state, action) => {
                state.loading.pop();
                state.isLoading = !!state.loading.length;
                state.adviserName = action.payload.adviserName;
                state.adviserGroup = action.payload.adviserGroup;
            })
            .addCase(getByReferralCodeAsync.rejected, (state, action) => {
                state.loading.pop();
                state.isLoading = !!state.loading.length;
                state.errors = action.payload as ProblemDetails;
            })
            .addCase(upsertAsync.pending, (state, action) => {
                state.responses = { ...state.responses, ...action.meta.arg.responses };
                state.isSubmitting = true;
                state.errors = undefined;
            })
            .addCase(upsertAsync.fulfilled, (state) => {
                state.isSubmitting = false;
            })
            .addCase(upsertAsync.rejected, (state, action) => {
                state.isSubmitting = false;
                state.errors = action.payload as ProblemDetails;
            })
            .addCase(completeAsync.pending, (state) => {
                state.isSubmitting = true;
                state.errors = undefined;
            })
            .addCase(completeAsync.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.url = action.payload.url;
            })
            .addCase(completeAsync.rejected, (state, action) => {
                state.isSubmitting = false;
                state.errors = action.payload as ProblemDetails;
            });
    }
});

export default clientQuestionnairesSlice.reducer;
