import { createTheme } from "@material-ui/core/styles";
import RadioCheckedIcon from "@material-ui/icons/CheckBox";
import RadioIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Colors } from "./components/controls/helpers";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Blue,
    },
    secondary: {
      main: Colors.Green,
    },
    text: {
      disabled: Colors.Grey,
      primary: Colors.Black,
      hint: Colors.DarkGrey,
    },
    background: {
      default: Colors.LightGrey,
    },
    info: {
      main: Colors.Blue,
    },
    success: {
      main: Colors.Blue,
    },
    error: {
      main: Colors.Red,
    },
    warning: {
      main: Colors.Orange,
    },
    common: {
      black: Colors.Black,
      white: Colors.White,
    },
  },
  typography: {
    fontFamily: ["Sons", "Helvetica", "Arial", "sans-serif"].join(","),
    allVariants: {
      lineHeight: 1.5,
    },
    h1: {
      fontWeight: 500,
      fontSize: "4.5rem",
      "@media(min-width: 768px)": {
        fontSize: "5.00rem",
      },
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.75rem",
      "@media(min-width: 768px)": {
        fontSize: "2rem",
      },
    },
    caption: {
      fontSize: "0.66rem",
      lineHeight: 1.1,
      "@media(min-width: 768px)": {
        fontSize: "0.75rem",
      },
    },
    fontWeightBold: 500,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
  props: {
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      icon: <RadioIcon />,
      checkedIcon: <RadioCheckedIcon />,
      color: "primary",
    },
    MuiTooltip: {
      enterTouchDelay: 1,
      leaveTouchDelay: 5000,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: "1rem",
        fontWeight: 400,
        borderRadius: "1.5rem",
        padding: "9px 1.125rem 11px",
        textTransform: "none",
      },
      contained: {
        backgroundColor: Colors.MediumGrey,
        color: Colors.Black,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        color: Colors.White,
      },
      containedSecondary: {
        color: Colors.White,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: Colors.Grey,
      },
    },
    MuiFormControl: {
      root: {
        borderRadius: "0.75rem",
      },
    },
    MuiFormLabel: {
      root: {
        color: Colors.DarkGrey,
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "0.75rem",
      },
    },
    MuiMobileStepper: {
      dot: {
        backgroundColor: Colors.White,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "0.75rem",
      },
      input: {
        padding: 15,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "0.75rem",
      },
      elevation1: {
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 3px 5px -1px rgba(0, 0, 0, 0.1)",
      },
    },
    MuiSelect: {
      root: {
        borderRadius: "0.75rem",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: Colors.White,
      },
    },
    MuiTextField: {
      root: {
        borderRadius: "0.75rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 0,
        border: `1px solid ${Colors.Grey}`,
        backgroundColor: Colors.White,
        color: Colors.DarkGrey,
        fontSize: "0.75em",
        boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.06), 0px 3px 5px -1px rgba(0, 0, 0, 0.1)",
        padding: 12,
      },
    },
    MuiTableCell: {
      body: {
        borderColor: "transparent",
      },
    },
    MuiTableRow: {
      hover: {
        "&&:hover": {
          backgroundColor: Colors.White,
        },
      },
    },
  },
});

export default theme;
