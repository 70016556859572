import Typography from '@material-ui/core/Typography';
import { Colors } from '.';

interface TextProps {
    children: any;
    hideBorderBottom?: boolean;
    hideMarginBottom?: boolean;
    hideMarginTop?: boolean;
}

export default (props: TextProps) => <Typography variant="subtitle1" style={{ borderBottom: `${props.hideBorderBottom ? '0' : '1'}px solid ${Colors.Grey}`, color: Colors.Black, fontSize: '1.14rem', fontWeight: 500, marginBottom: props.hideMarginBottom ? 0 : 24, marginTop: props.hideMarginTop ? 0 : 24 }}>{props.children}</Typography>
