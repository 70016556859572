import { Icon, makeStyles } from '@material-ui/core';
import { Colors } from '.';
import { Product } from '../../../api';

export default (props: { product: Product, highlighted?: boolean, fullProductName?: boolean }) => {
    const classes = useStyles();
    const { product, fullProductName, highlighted } = props;
    let productName: string | undefined;

    switch (product) {
        case Product.KiwiSaver:
            productName = fullProductName ? 'KiwiSaver' : 'KS';
            break;
        case Product.WealthBuilder:
            productName = fullProductName ? 'Wealth Builder' : 'WB';
            break;
        case Product.IncomeGenerator:
            productName = fullProductName ? 'Income Generator' : 'IG';
            break;
        default:
            break;
    }

    return <Icon className={classes.roundBorderedIcon} style={{ backgroundColor: Colors.White, borderColor: highlighted ? Colors.Black : Colors.Grey, color: highlighted ? Colors.Black : Colors.Grey }}>
        <div className={classes.productText}>{productName}</div>
    </Icon>
}

const useStyles = makeStyles({ 
    roundBorderedIcon: {
        border: `1px solid ${Colors.DarkGrey}`,
        borderRadius: '0.5rem',
        height: 27,
        width: 'auto',
        minWidth: 30,
        display: 'inline-flex',
        marginRight: 3,
        textAlign: 'center',
        alignItems: 'center',
        '&:last-child': {
            marginRight: 0
        }
    },
    productText: {
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 500,
        padding: '0 5px'
    }
}, { name: 'ProductIcon' });
