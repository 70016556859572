import Highcharts, { DashStyleValue, Options, PlotSeriesDataLabelsOptions, SeriesOptionsType, SymbolKeyValue } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Colors } from '.';

interface ChartProps {
    backgroundColor?: string;
    series?: Series[];
    type?: string;
    startLabel: string;
    endLabel: string;
    dataLabelOptions?: PlotSeriesDataLabelsOptions;
}

interface Marker {
    enabled?: boolean;
    radius?: number;
    fillColor?: string;
    symbol?: string;
}

interface Point {
    x?: number | undefined;
    y?: number | undefined;
    marker?: Marker | undefined;
}

export interface Series {
    color?: string | undefined;
    data?: Point[] | undefined;
    value?: number | undefined;
    name?: string | undefined;
}

export const defaultStyleProps = () => ({
    axisGridLineDashStyle: 'ShortDot' as DashStyleValue,
    axisGridLineColor: Colors.Grey,
    lineWidth: 4
});

export const defaultMarker = (props: { fillColor: string, radius?: number, symbol?: SymbolKeyValue }) => ({
    enabled: true,
    radius: props.radius || 5,
    fillColor: props.fillColor,
    symbol: props.symbol || 'round'
});

const getChartProps = ({ backgroundColor, series, type, startLabel, endLabel, dataLabelOptions }: ChartProps): Options => {
    const defaultStyle = defaultStyleProps();

    return {
        title: undefined,
        chart: {
            type: type,
            height: 480,
            backgroundColor: !backgroundColor ? Colors.LightGrey : backgroundColor
        },
        legend: {
            enabled: false
        },
        series: series as SeriesOptionsType[],
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false
            },
            gridLineDashStyle: defaultStyle.axisGridLineDashStyle,
            gridLineColor: defaultStyle.axisGridLineColor
        },
        xAxis: {
            tickInterval: 1,
            tickLength: 0,
            gridLineWidth: 0,
            title: {
                text: null
            },
            labels: {
                style: {
                    fontSize: '11px',
                    fontFamily: 'Sons,Helvetica,Arial,sans-serif'
                },
                formatter: (props) => {
                    if (props.isFirst)
                        return startLabel;
                    if (props.isLast)
                        return endLabel;
                    else return '';
                }
            },
            gridLineDashStyle: defaultStyle.axisGridLineDashStyle,
            gridLineColor: defaultStyle.axisGridLineColor,
            lineColor: series?.length ? defaultStyle.axisGridLineColor : 'transparent'
        },
        plotOptions: {
            spline: {
                lineWidth: defaultStyle.lineWidth
            },
            areaspline: {
                lineWidth: 4,
                stacking: 'normal',
                fillOpacity: 0.21
            },
            series: {
                enableMouseTracking: false,
                label: {
                    connectorAllowed: false
                },
                marker: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false,
                    align: 'right',
                    style: {
                        textOutline: 'none'
                    },
                    ...dataLabelOptions
                }
                
            }
        },
        credits: {
            enabled: false
        }
    }
};

export default (props: ChartProps) => <HighchartsReact highcharts={Highcharts} options={getChartProps(props)} />
