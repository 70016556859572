import { ReactNode } from 'react';
import { InputLabelProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import HelpTooltip from './HelpTooltip';
import { Colors } from '.';

export default ({ label, tooltip }: InputLabelProps & { label: ReactNode; tooltip?: string | ReactNode; }) =>
    <Typography style={{ color: Colors.DarkGrey, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
        {label}
        <HelpTooltip title={tooltip} />
    </Typography>
