import { Variant } from "@material-ui/core/styles/createTypography";
import Typography from "@material-ui/core/Typography";
import { Colors } from "./";

interface TextProps {
  children: any;
  color?: string;
  hideMarginTop?: boolean;
  textAlign?: "center" | "left" | "right";
  variant?: "inherit" | Variant;
}

export default ({ children, color = Colors.DarkGrey, hideMarginTop, textAlign = "left", variant = "subtitle2" }: TextProps) => (
  <Typography style={{ marginTop: hideMarginTop ? 0 : 10, color, textAlign }} variant={variant}>
    {children}
  </Typography>
);
