import { ReactNode, useState } from 'react';
import { makeStyles, IconButton, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

interface AlertMessageProps {
    action?: ReactNode;
    message: string;
    error?: boolean;
    hideClose?: boolean;
}

export default (props: AlertMessageProps) => {
    const styles = useStyles();
    const { action, error, hideClose, message } = props;
    const [open, setOpen] = useState(true);
    return <Collapse in={open} style={{ width: '100%' }}>
        <Alert
            severity={error ? 'error' : 'info'}
            className={styles.alert}
            variant="filled"
            action={action ? action : !hideClose &&
                <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            {message}
        </Alert>
    </Collapse>
}

const useStyles = makeStyles({
    alert: {
        borderRadius: '0 !important',
        padding: '8px 20px'
    }
}, { name: 'AlertMessage' });
